<template>
<div>
<v-list width="500">
    <template v-for="(item, index) in items">

        <v-list-item three-line shaped :key="index" @click="handleClick(item)">
            <v-list-item-avatar :color="item.color">
                <v-icon x-small dark>{{ item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
                <v-list-item-subtitle class="overline" v-html="item.msg"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="caption">
                {{ item.timeLabel }}
            </v-list-item-action>
        </v-list-item>

    </template>

</v-list>
<v-btn block x-small color="warning" to="/notificaciones">Ver Todos</v-btn>
</div>
</template>

<script>
import Vue from "vue";
import axios from "axios";

export default {
    data: () => ({
        fab: false,
        items_total: [],
        items: []
    }),

    created() {
        this.getNotificaciones();

        this.$options.sockets.onmessage = data => {
            var data = JSON.parse(data.data);
            let notificacion = data["notificacion"];

            if (notificacion != undefined) {
                if (notificacion.usuario == this.$local_storage.get("sb_usuario")) {
                    this.items = [];

                    if (notificacion.estatus == 0) {
                        this.items_total.push({
                            title: notificacion.titulo,
                            msg: notificacion.mesage,
                            id: notificacion.id_notificacion,
                            color: "light-green",
                            icon: "account_circle",
                            timeLabel: "Hoy"
                        });

                        this.$root.$emit("notificationsNumber", this.items_total.length);

                        for (var i = this.items_total.length - 1; i >= 0; i--) {
                            this.items.push(this.items_total[i]);

                            if (this.items.length == 7) break;
                        }

                        this.playSoundAlert();
                    }
                }
            }
        };

        this.$root.$on("notificationsUpdate", val => {
            this.getNotificaciones();
        });
    },
    methods: {
        handleClick: function (item) {
            if (item.archivo != undefined) {
                this.$swal({
                    type: "success",
                    title: "Descargando...",
                    text: "Se ha comenzado la descarga del reporte.",
                    footer: ""
                });
                var id = item.archivo;
                var name = id.split("/");
                // this.$root.$dialogLoader.show('Espere un momento por favor..');
                axios
                    .post(
                        this.$hostname + "s3/download_url/", {
                            id: id
                        },

                    )

                    .then(response => {
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = response.data.mensaje;
                        a.target = "_blank";
                        //a.download = name[name.length - 1];
                        a.click();

                        a.parentNode.removeChild(a);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al descargar el reporte.",
                            footer: ""
                        });
                    }).then(() => {
                        // this.$root.$dialogLoader.hide();
                    });
            }
        },
        playSoundAlert() {
            var audio = new Audio("/sound/alert.mp3");
            audio.play();
        },
        getNotificaciones: function () {
            let id_usuario = this.$local_storage.get("sb_usuario");
            
            window.dialogLoader.show("Espere un momento por favor..");
            axios
                .post(this.$hostname + "notificaciones/list_notificaciones/", {
                    usuario: id_usuario
                })
                .then(response => {
                    let notificaciones = response.data.filter(notificacion => {
                        return notificacion.estatus == 0;
                    });

                    this.items = [];
                    this.items_total = [];
                    var msg = "";
                    var file = "";
                    for (var i = notificaciones.length - 1; i >= 0; i--) {
                        if (notificaciones[i].mensaje.startsWith("{")) {
                            file = JSON.parse(notificaciones[i].mensaje).file;
                            msg = JSON.parse(notificaciones[i].mensaje).texto;
                        } else {
                            file = undefined;
                            msg = notificaciones[i].mensaje;
                        }

                        this.items_total.push({
                            title: notificaciones[i].titulo,
                            msg: msg,
                            id: notificaciones[i].id_notificacion,
                            color: notificaciones[i].titulo == "Reporte: Error" ? "red" : "light-green",
                            icon: file !== undefined ? "cloud_download" : 'account_circle',
                            timeLabel: this.getDiferenciaTiempo(notificaciones[i].fecha),
                            archivo: file
                        });

                        if (this.items.length <= 7)
                            this.items.push({
                                title: notificaciones[i].titulo,
                                msg: msg,
                                id: notificaciones[i].id_notificacion,
                                color: notificaciones[i].titulo == "Reporte: Error" ? "red" : "light-green",
                                icon: file !== undefined ? "cloud_download" : 'account_circle',
                                timeLabel: this.getDiferenciaTiempo(notificaciones[i].fecha),
                                archivo: file
                            });
                    }

                    this.$root.$emit("notificationsNumber", notificaciones.length);
                })
                .catch(error => {
                    console.log(error);
                   
                });
            window.dialogLoader.hide();
        },
        getDiferenciaTiempo: function (fecha) {
            let fechaActual = this.$moment();
            let b = this.$moment(fecha);
            let dias = fechaActual.diff(b, "days"); // 1

            return dias == 0 ? "Hoy" : dias + " dias";
        },
    }
};
</script>
